<template>
    <div class="px-4">
        <b-form>            
            <div>
                <b-form-group v-for="attr in formAttributes" :key="attr.key"
                    :label-for="'input-' + attr.key"
                    label-class="mb-0 font-weight-light"
                >
                    <template #label>
                        <span class="font-weight-bold" v-html="$t(tKey + attr.key + '.label')" />
                        <font-awesome-icon v-if="attr.isConfirmed" class="ml-1 text-custom" icon="check-circle" />
                    </template>     
                    <b-form-checkbox v-if="attr.type === 'checkbox'"
                        :id="'input-' + attr.key"
                        v-model="form[attr.key]"
                        switch
                        class="text-dark border-0"
                    />               
                    <b-form-input v-else
                        :id="'input-' + attr.key"
                        v-model="form[attr.key]"
                        :type="attr.type ? attr.type : 'text'"
                        :placeholder="$t(tKey + attr.key + '.placeholder')"
                        :state="validateState(attr.key)"
                    />        
                    <b-form-invalid-feedback v-for="v in attr.validation" :key="v" :state="validateState(attr.key)">
                        <span v-if="!$v.form[attr.key][v]">
                            {{ attr.validationInput ? $t(tKey + attr.key + '.' + v, { input: validationInput }) : $t(tKey + attr.key + '.' + v) }}
                        </span>
                    </b-form-invalid-feedback>
                    <div class="d-flex justify-content-between">
                        <b-form-text v-if="attr.help" 
                            v-html="$t(tKey + attr.key + '.help')"
                        />
                        <b-form-text v-if="$v.form[attr.key].$params.maxLength && form[attr.key]" 
                            v-html="form[attr.key].length + '/' + $v.form[attr.key].$params.maxLength.max"
                        />                                        
                    </div>
                </b-form-group>
                <slot />
            </div>
        </b-form>        
    </div>
</template>

<script>
import { validationMixin } from "vuelidate"   
export default {
    name: 'SettingsForm',
    mixins: [
        validationMixin,
    ],
    props: {
        tKey: { type: String, required: true},
        form: { type: Object, required: true },
        formAttributes: { type: Array, required: true },
        validation: { type: Object, required: true },
        base: { type: Object, default: null },
    },
    data() {
        return {
            query: '',
            tagSearch: '',
        }
    },
    created() {
        this.reset()
    },
    methods: {
        reset() {
            this.loadBase()
            this.$v.form.$reset()
        },
        loadBase() {
            if(this.base)
                for(const prop in this.form)
                    if(this.base[prop]) this.form[prop] = this.base[prop]                        
        },
        validateState(name) {
            const { $dirty, $error } = this.$v.form[name]
            return $dirty ? !$error : null
        },
        submit(callback = null) {
            this.$v.form.$touch()
            if (this.$v.form.$anyError) return
            if(callback) callback()
        },
    },
    validations() {
        return this.validation
    },
}
</script>

<style lang="scss">

</style>